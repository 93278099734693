import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define types
interface OrderStatus {
  id: number;
  name: string;
}

interface OrderProps {
  orderStatuses: OrderStatus[];
}

// Initial state
const initialState: OrderProps = {
  orderStatuses: []
};

// Create order status slice
const order = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderStatuses(state, action: PayloadAction<OrderStatus[]>) {
      state.orderStatuses = action.payload;
    },
    clearOrderStatuses(state) {
      state.orderStatuses = [];
    }
  }
});

export default order.reducer;

// Export actions
export const { setOrderStatuses, clearOrderStatuses } = order.actions;

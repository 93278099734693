// third-party
import { createSlice } from '@reduxjs/toolkit';

// types
import { UserProps } from 'types/auth';

// initial state
export const initialState: UserProps = {
  user: null
};

// ----------------------------------------------------------------------

const profile = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    }
  }
});

// Actions
export const { setUser } = profile.actions;

// Reducer
export default profile.reducer;

import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemAvatar,
  ListItemText,
  Paper,
  Popper,
  Tooltip,
  Typography,
  useMediaQuery,
  Skeleton,
  Stack
} from '@mui/material';
import SimpleBar from 'simplebar-react';

// project import
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';

//  assets
import { BellOutlined, CheckCircleOutlined } from '@ant-design/icons';

import BulkProduct from 'assets/images/notifications/BulkProduct';
import Campaign from 'assets/images/notifications/Campaign';
import Delivered from 'assets/images/notifications/Delivered';
import DeliveryReturn from 'assets/images/notifications/DeliveryReturn';
import OrderPayment from 'assets/images/notifications/OrderPayment';
import OrderReturn from 'assets/images/notifications/OrderReturn';
import Pending from 'assets/images/notifications/Pending';
import Pickup from 'assets/images/notifications/Pickup';
import ProductError from 'assets/images/notifications/ProductError';
import ProductUpdate from 'assets/images/notifications/ProductUpdate';
import ProUser from 'assets/images/notifications/ProUser';
import Question from 'assets/images/notifications/Question';
import Request from 'assets/images/notifications/Request';
import ReturnPayment from 'assets/images/notifications/ReturnPayment';
import Review from 'assets/images/notifications/Review';
import Shipment from 'assets/images/notifications/Shipment';
import Stock from 'assets/images/notifications/Stock';
import TransferPayment from 'assets/images/notifications/TransferPayment';
import User from 'assets/images/notifications/User';

import { listNotifications } from 'services/notification';
import useConfig from 'hooks/useConfig';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isErrorResponse } from 'utils/globals';

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

type NotificationIconName =
  | 'bulk-product'
  | 'campaign'
  | 'delivered'
  | 'delivery-return'
  | 'order-payment'
  | 'order-return'
  | 'pending'
  | 'pickup'
  | 'product-error'
  | 'product-update'
  | 'pro-user'
  | 'question'
  | 'request'
  | 'return-payment'
  | 'review'
  | 'shipment'
  | 'stock'
  | 'transfer-payment'
  | 'user';

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const { i18n } = useConfig();
  const { t } = useTranslation();

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);

  const [notifications, setNotifications] = useState<{ hasMore: boolean; data: any[]; read: number }>({
    data: [],
    hasMore: false,
    read: 0
  });

  useEffect(() => {
    getNotifications();
  }, [page]);

  const handleToggle = async () => {
    if (open) {
      setPage(0);
      setNotifications({
        data: [],
        hasMore: false,
        read: 0
      });
    }
    setOpen((prevOpen) => !prevOpen);
    if (!open) {
      setLoading(true);
      getNotifications();
    }
  };

  const getNotifications = async () => {
    try {
      const res = await listNotifications(i18n, page);
      setNotifications({
        data: [...notifications?.data, ...res.data.item?.notifications?.data],
        hasMore: res.data?.item?.notifications?.pagination?.more,
        read: res.data.item?.new_count ?? 0
      });
    } catch (e) {
      if (isErrorResponse(e)) console.error('An Error has occured: ', e.message);
      else console.error('An Error has occured: ', e);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    setPage(0);
    setNotifications({
      data: [],
      hasMore: false,
      read: 0
    });
  };

  const iconBackColorOpen = 'grey.300';
  const iconBackColor = 'grey.100';

  const notificationsIcons = {
    'bulk-product': (color: string) => <BulkProduct color={color} />,
    campaign: (color: string) => <Campaign color={color} />,
    delivered: (color: string) => <Delivered color={color} />,
    'delivery-return': (color: string) => <DeliveryReturn color={color} />,
    'order-payment': (color: string) => <OrderPayment color={color} />,
    'order-return': (color: string) => <OrderReturn color={color} />,

    pending: (color: string) => <Pending color={color} />,
    pickup: (color: string) => <Pickup color={color} />,
    'product-error': (color: string) => <ProductError color={color} />,
    'product-update': (color: string) => <ProductUpdate color={color} />,

    'pro-user': (color: string) => <ProUser color={color} />,
    question: (color: string) => <Question color={color} />,
    request: (color: string) => <Request color={color} />,

    'return-payment': (color: string) => <ReturnPayment color={color} />,
    review: (color: string) => <Review color={color} />,
    shipment: (color: string) => <Shipment color={color} />,
    stock: (color: string) => <Stock color={color} />,
    'transfer-payment': (color: string) => <TransferPayment color={color} />,
    user: (color: string) => <User color={color} />
  };

  const getNotificationBGColor = (color: string) => {
    switch (color.toUpperCase()) {
      case '#FF9800':
        return '#FFF7E6';
      case '#2D9B15':
        return '#F6FFED';
      case '#FF0000':
        return '#FFF1F0';
      default:
        return '#E6FFFB';
    }
  };

  const notificationItem = (item: any) => {
    const iconColor = item?.color ?? '#08979C';
    const iconId: NotificationIconName = (item?.icon as NotificationIconName) ?? 'user';

    // Ensure iconId is valid before calling it as a function
    const IconComponent = notificationsIcons[iconId] ?? notificationsIcons['user'];

    return (
      <ListItemButton selected={!item?.seen} href={`${process.env.REACT_APP_URL}apps/${item?.link ?? '/'}`}>
        <ListItemAvatar>
          <Avatar
            sx={{
              color: 'success.main',
              bgcolor: getNotificationBGColor(iconColor)
            }}
          >
            {IconComponent(iconColor)}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography
              variant="h6"
              color={iconColor}
              style={{
                wordWrap: 'break-word',
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3
              }}
            >
              {item.title}
            </Typography>
          }
        />
        <ListItemSecondaryAction>
          <Typography variant="caption" textAlign={'end'} noWrap>
            {item.time_ago}
          </Typography>
        </ListItemSecondaryAction>
      </ListItemButton>
    );
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={notifications?.read} color="primary">
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position={matchesXs ? 'top' : 'top-right'} sx={{ overflow: 'hidden' }} in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: '100%',
                minWidth: 400,
                maxWidth: 500,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 500
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title={t('notification')}
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <>
                      {notifications?.read > 0 && (
                        <Tooltip title="Mark as all read">
                          <IconButton
                            color="success"
                            size="small"
                            onClick={() => {
                              setNotifications((prevData) => {
                                return {
                                  ...prevData,
                                  read: 0
                                };
                              });
                            }}
                          >
                            <CheckCircleOutlined style={{ fontSize: '1.15rem' }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  }
                >
                  <SimpleBar style={{ maxHeight: 350 }}>
                    <List
                      component="nav"
                      sx={{
                        p: 0,
                        '& .MuiListItemButton-root': {
                          py: 0.5,
                          '&.Mui-selected': { bgcolor: 'grey.50', color: 'text.primary' },
                          '& .MuiAvatar-root': avatarSX,
                          '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                        }
                      }}
                    >
                      {loading ? (
                        <ListItemButton>
                          <Skeleton animation="wave" width="100%" height="100%" />
                        </ListItemButton>
                      ) : notifications?.data.length > 0 ? (
                        <InfiniteScroll
                          dataLength={notifications.data.length}
                          next={() => setPage(page + 1)}
                          height={notifications.data.length > 7 ? 300 : notifications.data.length * 35}
                          hasMore={notifications.hasMore}
                          loader={
                            <Stack alignItems="center" sx={{ mx: 2 }} spacing={0.5}>
                              <Skeleton animation="wave" width="100%" height="100%" />
                              <Skeleton animation="wave" width="100%" height="100%" />
                              <Skeleton animation="wave" width="100%" height="100%" />
                            </Stack>
                          }
                          endMessage={
                            <Stack alignItems="center" sx={{ background: 'white' }}>
                              <h4>No more data</h4>
                            </Stack>
                          }
                          scrollThreshold={0.8}
                          scrollableTarget="scrollableDiv"
                          style={{
                            border: '1px solid #D9D9D9',
                            borderTop: '0px',
                            borderRadius: '4px',
                            background: 'white'
                          }}
                        >
                          {notifications?.data.map((item: any, index: number) => {
                            return notificationItem(item);
                          })}
                        </InfiniteScroll>
                      ) : (
                        <ListItemButton>
                          <ListItemText primary={<Typography variant="h6">You don't have any notification</Typography>} />
                        </ListItemButton>
                      )}
                    </List>
                  </SimpleBar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Notification;

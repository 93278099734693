import useConfig from 'hooks/useConfig';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { getInvoicesCount } from 'services/finance';

// initial state
const initialState = {
  pendingInvoices: 0
};

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const AppContext = createContext(initialState);

type AppProviderProps = {
  children: ReactNode;
};

function AppProvider({ children }: AppProviderProps) {
  const { i18n } = useConfig();
  const [info, setInfo] = useState(initialState);

  const fetchPendingInvoices = async () => {
    try {
      const response = await getInvoicesCount({ i18n });

      // Update the config state with the new pendingInvoices count
      setInfo((prevInfo) => ({
        ...prevInfo,
        pendingInvoices: response.count
      }));
    } catch (error) {
      console.error('Error fetching pending invoices count:', error);
    }
  };

  useEffect(() => {
    fetchPendingInvoices();
  }, []);

  return (
    <AppContext.Provider
      value={{
        ...info
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export { AppProvider, AppContext };

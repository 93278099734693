import { SuccessResponseEntity } from 'types/app';
import { ExportItemProps } from 'types/export';
import axiosServices, { responseError, responseSuccess } from 'utils/axios';
import { isErrorResponse } from 'utils/globals';

export const acceptTermsApi = async (id: string, i18n: string) => {
  try {
    const formData = new FormData();
    formData.append('terms_accepted', '1');
    const res = await axiosServices.put<SuccessResponseEntity<[]>>(`users/${id}/terms`, formData, {
      headers: { 'Accept-Language': i18n }
    });
    if (!res.data.success) {
      responseError(res.data.message || '');
      return false;
    }
    return true;
  } catch (err) {
    if (isErrorResponse(err)) {
      responseError(err?.message);
    } else {
      responseError();
    }
    return false;
  }
};

////////////////////////////
// ** super-admin api ** //
//////////////////////////

// List super-admins & admins
export function listUsers(
  type: 'admin' | 'superadmin',
  page: number,
  offset: number,
  trashed: string,
  search: string,
  filterParams: any,
  setAdmins: (data: any) => void,
  setPagination: (data: any) => void,
  i18n: string,
  isExporting?: boolean,
  updateExportedList?: (item: ExportItemProps) => void
) {
  const endpoint = `users${isExporting ? '/data/export' : ''}?type=${type}&page=${page}&offset=${offset}&trashed=${trashed}${
    search ? `&search[value]=${search}` : ''
  }${filterParams.date_from ? `&custom_search[date_from]=${filterParams.date_from}` : ''}${
    filterParams.date_to ? `&custom_search[date_to]=${filterParams.date_to}` : ''
  }${filterParams.filter_status ? `&custom_search[status]=${filterParams.filter_status}` : ''}`;

  return axiosServices.get(endpoint, { headers: { 'Accept-Language': i18n } }).then((res) => {
    if (!isExporting) {
      setPagination(res.data.items.meta.pagination);
      setAdmins(res.data.items.data);
    } else {
      // const item = {
      //   file: res.data.items?.name,
      //   uuid: res.data.items?.uuid,
      //   endpoint: endpoint
      // };
      // updateExportedList?.(item);
      responseSuccess('export.success', 'common');
    }
  });
}

export function deleteSuperAdmin(id: number, i18n: string) {
  return axiosServices.delete(`users/${id}`, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      responseSuccess('admin.success.super_delete_success', 'user_managment');
    },
    (err: any) => {
      responseError('admin.errors.super_delete_error', 'user_managment');
    }
  );
}

export function createSuperAdmin(form: FormData, i18n: string) {
  return axiosServices.post(`users`, form, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      if (res.data.errors || (res.data.message && typeof res.data.message != 'string')) {
        responseError('admin.errors.super_create_error', 'user_managment');
        return { errors: res.data.errors ?? res.data.message };
      }
      responseSuccess('admin.success.super_create_success', 'user_managment');
      return { success: true };
    },
    (err: any) => {
      responseError('admin.errors.super_create_error', 'user_managment');
      return err;
    }
  );
}

export function updateSuperAdmin(id: number, form: FormData, i18n: string) {
  return axiosServices.post(`users/${id}`, form, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      if (res.data.errors || (res.data.message && typeof res.data.message != 'string')) {
        responseError('admin.errors.super_update_error', 'user_managment');
        return { errors: res.data.errors ?? res.data.message };
      }
      return { success: true };
    },
    (err: any) => {
      responseError('admin.errors.super_update_error', 'user_managment');
      return err;
    }
  );
}

export function updateSuperAdminStatus(id: number, form: FormData, i18n: string) {
  return axiosServices.post(`users/${id}/change-status-and-role`, form, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      if (res.data.errors) {
        responseError('admin.errors.super_update_error', 'user_managment');
        return res.data;
      }
      responseSuccess('admin.success.super_update_success', 'user_managment');
      return { success: true };
    },
    (err: any) => {
      responseError('admin.errors.super_update_error', 'user_managment');
      return { errors: err.message };
    }
  );
}

export function restoreSuperAdmin(id: number, i18n: string) {
  return axiosServices.post(`users/${id}/restore`, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      responseSuccess('admin.success.super_restore_success', 'user_managment');
    },
    (err: any) => {
      responseError('admin.errors.super_restore_error', 'user_managment');
    }
  );
}

//////////////////////
// ** admin api ** //
////////////////////

export function deleteAdmin(id: number, i18n: string) {
  return axiosServices.delete(`users/${id}`, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      responseSuccess('admin.success.delete_success', 'user_managment');
    },
    (err: any) => {
      responseError('admin.errors.delete_error', 'user_managment');
    }
  );
}

export function createAdmin(form: FormData, i18n: string) {
  return axiosServices.post(`users`, form, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      if (res.data.errors || (res.data.message && typeof res.data.message != 'string')) {
        responseError('admin.errors.create_error', 'user_managment');
        return { errors: res.data.errors ?? res.data.message };
      }
      responseSuccess('admin.success.create_success', 'user_managment');
      return { success: true };
    },
    (err: any) => {
      responseError('admin.errors.create_error', 'user_managment');
      return err;
    }
  );
}

export function updateAdmin(id: number, form: FormData, i18n: string) {
  return axiosServices.post(`users/${id}`, form, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      if (res.data.errors || (res.data.message && typeof res.data.message != 'string')) {
        responseError('admin.errors.update_error', 'user_managment');
        return { errors: res.data.errors ?? res.data.message };
      }
      return { success: true };
    },
    (err: any) => {
      responseError('admin.errors.update_error', 'user_managment');
      return err;
    }
  );
}

export function updateAdminStatus(id: number, form: FormData, i18n: string) {
  return axiosServices.post(`users/${id}/change-status-and-role`, form, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      if (res.data.errors) {
        responseError('admin.errors.update_error', 'user_managment');
        return res.data;
      }
      responseSuccess('admin.success.update_success', 'user_managment');
      return { success: true };
    },
    (err: any) => {
      responseError('admin.errors.update_error', 'user_managment');
      return { errors: err.message };
    }
  );
}

export function restoreAdmin(id: number, i18n: string) {
  return axiosServices.post(`users/${id}/restore`, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      responseSuccess('admin.success.restore_success', 'user_managment');
    },
    (err: any) => {
      responseError('admin.errors.restore_error', 'user_managment');
    }
  );
}

export function updateProfileData(type: string, id: string, i18n: string, payload: any) {
  return axiosServices.post(`users/${id}`, payload, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      if (res.data.code !== 200 || typeof res.data?.message != 'string') {
        responseError();
        return res.data;
      } else {
        responseSuccess('admin.success.update_success', 'user_managment');
        return res?.data;
      }
    },
    (err: any) => {
      responseError();
      return err;
    }
  );
}

export function updateUserLanguage(form: FormData, i18n: string, userId: string | undefined) {
  return axiosServices.post(`users/${userId}/change-default-lang`, form, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      if (res.data.errors) {
        responseError('admin.errors.update_lang_error', 'user_managment');
        return res.data;
      }
      return { success: true };
    },
    (err: any) => {
      responseError('admin.errors.update_lang_error', 'user_managment');
      return { errors: err.message };
    }
  );
}

import { createContext, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// project import
import config from 'config';

// types
import { CustomizationProps, I18n, MenuOrientation, ThemeDirection } from 'types/config';

// initial state
const initialState: CustomizationProps = {
  ...config,
  onChangeLocalization: (lang: I18n) => { },
  onChangeDirection: (direction: ThemeDirection) => { },
  onChangeMiniDrawer: (miniDrawer: boolean) => { },
  onChangeMenuOrientation: (menuOrientation: MenuOrientation) => { }
};

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const ConfigContext = createContext(initialState);

type ConfigProviderProps = {
  children: ReactNode;
};

function ConfigProvider({ children }: ConfigProviderProps) {
  const [config, setConfig] = useState(initialState);
  const { i18n } = useTranslation();

  useEffect(() => {
    const localConfig = window.localStorage.getItem('config');
    if (!localConfig) {
      onChangeMenuOrientation(config.menuOrientation);
      onChangeLocalization(config.i18n);
    } else {
      const parsedConfig = JSON.parse(localConfig);
      onChangeLocalization(parsedConfig.i18n);
      let orientation = parsedConfig.menuOrientation;
      if (parsedConfig.menuOrientation === MenuOrientation.VERTICAL) {
        const updatedMenuOrientation = window.localStorage.getItem('updatedMenuOrientation');
        if (!updatedMenuOrientation) {
          window.localStorage.setItem('updatedMenuOrientation', 'true');
          orientation = MenuOrientation.HORIZONTAL;
        }
      }
      onChangeMenuOrientation(orientation);
    }
  }, []);

  const onChangeLocalization = (lang: I18n) => {
    if (!lang) lang = 'tr'
    i18n.changeLanguage(lang);
    setConfig({
      ...config,
      i18n: lang
    });
    localStorage.setItem(
      'config',
      JSON.stringify({
        ...config,
        i18n: lang
      })
    );
    document.documentElement.lang = lang;
  };

  const onChangeDirection = (direction: ThemeDirection) => {
    setConfig({
      ...config,
      themeDirection: direction
    });
  };

  const onChangeMiniDrawer = (miniDrawer: boolean) => {
    setConfig({
      ...config,
      miniDrawer
    });
  };

  const onChangeMenuOrientation = (layout: MenuOrientation) => {
    setConfig({
      ...config,
      menuOrientation: layout
    });
    localStorage.setItem(
      'config',
      JSON.stringify({
        ...config,
        menuOrientation: layout
      })
    );
  };

  return (
    <ConfigContext.Provider
      value={{
        ...config,
        onChangeLocalization,
        onChangeDirection,
        onChangeMiniDrawer,
        onChangeMenuOrientation
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

export { ConfigProvider, ConfigContext };

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define types
interface VatRate {
  value: string;
  label: string;
}

interface ProductProps {
  vatRates: VatRate[];
}

// Initial state
const initialState: ProductProps = {
  vatRates: []
};

// Create product status slice
const product = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setVateRates(state, action: PayloadAction<VatRate[]>) {
      state.vatRates = action.payload;
    },
  }
});

export default product.reducer;

// Export actions
export const { setVateRates } = product.actions;

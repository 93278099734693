// assets
import { CreditCardOutlined, AuditOutlined } from '@ant-design/icons';

// type
import { EarningsIcon } from 'assets/images/icons/earnings';
import { PaymetnsIcons } from 'assets/images/icons/payments';
import { NavItemType } from 'types/menu';
import Finance from 'assets/images/side-menu/Finance';

// icons
const icons = {
  CreditCardOutlined,
  AuditOutlined,
  EarningsIcon,
  PaymetnsIcons
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const finance: NavItemType = {
  id: 'finance',
  title: 'finance',
  type: 'group',
  icon: (props) => {
    return <Finance fillColor={props?.style?.color} />;
  },
  permissionKey: ['VIEW_ACCOUNTING_LIST', 'LIST_EARNINGS', 'VIEW_INVOICES_LIST'],
  children: [
    {
      id: 'accounting',
      title: 'accounting',
      type: 'item',
      permissionKey: ['VIEW_ACCOUNTING_LIST'],
      url: '/apps/finance/accounting/list',
      icon: icons.CreditCardOutlined
    },
    {
      id: 'earnings',
      title: 'earnings',
      type: 'collapse',
      permissionKey: ['LIST_EARNINGS'],
      icon: icons.EarningsIcon,
      children: [
        {
          id: 'past',
          title: 'past',
          type: 'item',
          permissionKey: ['LIST_EARNINGS'],
          url: '/apps/finance/earnings/past/list'
        },
        {
          id: 'future',
          title: 'future',
          type: 'item',
          permissionKey: ['LIST_EARNINGS'],
          url: '/apps/finance/earnings/future/list'
        }
      ]
    },
    {
      id: 'main-invoices',
      title: 'invoices',
      type: 'collapse',
      permissionKey: ['ALL', 'VIEW_INVOICES_LIST'],
      icon: icons.AuditOutlined,
      children: [
        {
          id: 'invoices',
          title: 'customer-invoices',
          type: 'item',
          permissionKey: ['VIEW_INVOICES_LIST'],
          url: '/apps/orders/invoices/list',
          getChipLabel: async ({ pendingInvoices }) => {
            pendingInvoices = pendingInvoices || 0;
            return pendingInvoices > 99 ? '+99' : pendingInvoices.toString();
          },
          chip: {
            color: 'error',
            size: 'small',
            variant: 'light'
          }
        },
        {
          id: 'coupon-invoices',
          title: 'coupon-invoices',
          type: 'item',
          permissionKey: ['ALL'],
          url: '/apps/finance/coupon-invoices/list'
        }
      ]
    },
    {
      id: 'payments',
      title: 'payments',
      type: 'item',
      permissionKey: ['ALL'],
      url: '/apps/finance/payments/list',
      icon: (props) => {
        const isSelected = props?.style?.color == 'selected';
        return <PaymetnsIcons style={{ fontSize: '1rem', fill: isSelected ? '#222A95' : '#323232' }} />;
      }
    }
  ]
};

export default finance;

const Finance = ({ fillColor = '#2A282F' }: any) => {
  return (
    <svg width="22" height="22" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.89276 5.43685C8.67541 5.43685 8.49901 5.61325 8.49901 5.8306C8.49901 6.69318 7.42695 7.12424 6.79278 7.30429V6.04488L7.72621 5.6716C7.92837 5.59123 8.02652 5.36177 7.94568 5.1597C7.86541 4.95754 7.63489 4.86045 7.43378 4.94024L6.79278 5.19642V4.73182L7.72621 4.35855C7.92837 4.27818 8.02652 4.04881 7.94568 3.84665C7.86541 3.64449 7.63489 3.54739 7.43378 3.62718L6.79278 3.88395V3.33687C6.79278 3.11952 6.61638 2.94312 6.39903 2.94312C6.18167 2.94312 6.00527 3.11952 6.00527 3.33687V4.19897L4.80931 4.67722C4.60714 4.75749 4.509 4.98696 4.58984 5.18912C4.65127 5.34341 4.79883 5.43685 4.95524 5.43685C5.00407 5.43685 5.05396 5.42743 5.10126 5.40849L6.00527 5.04733V5.51193L4.80931 5.99027C4.60714 6.07054 4.509 6.30001 4.58984 6.50208C4.65127 6.65598 4.79883 6.74942 4.95524 6.74942C5.00407 6.74942 5.05396 6.7399 5.10126 6.72106L6.00527 6.3598V7.79936C6.00527 7.91491 6.05565 8.02412 6.14332 8.09862C6.21532 8.16005 6.30607 8.19312 6.39903 8.19312C6.4195 8.19312 6.43998 8.19158 6.46045 8.1884C6.57591 8.17004 9.28651 7.72015 9.28651 5.8306C9.28651 5.61325 9.11011 5.43685 8.89276 5.43685Z"
        fill={fillColor}
      />
      <path
        d="M9.1974 9.66504H4.8042C4.57768 9.66504 4.39404 9.84868 4.39404 10.0752C4.39404 10.3017 4.57768 10.4854 4.8042 10.4854H9.1974C9.42392 10.4854 9.60756 10.3017 9.60756 10.0752C9.60756 9.84868 9.42392 9.66504 9.1974 9.66504Z"
        fill={fillColor}
      />
      <path
        d="M11.8335 0H2.16846C1.94194 0 1.7583 0.18364 1.7583 0.410155V12.3817C1.7583 12.4904 1.80153 12.5948 1.87845 12.6717L3.0866 13.8799C3.24676 14.04 3.50647 14.04 3.66664 13.8799L4.58476 12.9617L5.50286 13.8799C5.57977 13.9568 5.68409 14 5.79289 14C5.90166 14 6.00601 13.9568 6.08293 13.8799L7.00099 12.9617L7.91911 13.8799C7.99603 13.9568 8.10034 14 8.20912 14C8.31792 14 8.42223 13.9568 8.49915 13.8799L9.41724 12.9617L10.3354 13.8799C10.4155 13.9599 10.5204 14 10.6254 14C10.7303 14 10.8353 13.96 10.9154 13.8799L12.1236 12.6717C12.2005 12.5948 12.2437 12.4904 12.2437 12.3817V0.410155C12.2437 0.18364 12.0601 0 11.8335 0ZM10.6254 13.0098L9.70725 12.0917C9.63033 12.0147 9.52599 11.9715 9.41722 11.9715C9.30841 11.9715 9.2041 12.0147 9.12718 12.0917L8.20909 13.0098L7.29097 12.0917C7.13082 11.9315 6.87111 11.9315 6.71093 12.0917L5.79281 13.0098L4.87472 12.0917C4.7978 12.0147 4.69348 11.9715 4.58468 11.9715C4.47591 11.9715 4.37159 12.0147 4.29467 12.0917L3.37656 13.0098L2.57856 12.2118V0.820311H11.4233V12.2118H11.4234L10.6254 13.0098Z"
        fill={fillColor}
      />
    </svg>
  );
};
export default Finance;

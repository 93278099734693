// types
import { DefaultConfigProps, MenuOrientation, ThemeDirection } from 'types/config';

// ==============================|| THEME CONSTANT  ||============================== //

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';

export const APP_DEFAULT_PATH = '/apps/dashboard';
export const HORIZONTAL_MAX_ITEM = 8;
export const DRAWER_WIDTH = 260;

// ==============================|| THEME CONFIG  ||============================== //

const config: DefaultConfigProps = {
  i18n: 'tr',
  menuOrientation: MenuOrientation.HORIZONTAL,
  miniDrawer: false,
  themeDirection: ThemeDirection.LTR
};

export default config;

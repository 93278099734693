// action - state management

import { createSlice } from '@reduxjs/toolkit';

// types

interface customerProps {
  customerId: number | null;
}

// initial state
export const initialState: customerProps = {
  customerId: null
};

// ==============================|| AUTH REDUCER ||============================== //

const customer = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    editedCustomerId(state, action) {
      state.customerId = action.payload;
    }
  }
});

export default customer.reducer;

export const { editedCustomerId } = customer.actions;

import { useEffect, useLayoutEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Typography, useMediaQuery } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { Menu } from 'menu-items/old-dashboard';

import { dispatch, useSelector } from 'store';
import { activeItem } from 'store/reducers/menu';
import useConfig from 'hooks/useConfig';
import { HORIZONTAL_MAX_ITEM } from 'config';

// types
import { NavItemType } from 'types/menu';
import { MenuOrientation } from 'types/config';
import useAuth from 'hooks/useAuth';
import { checkPermissionMatrix, checkSellerSideMenu } from 'utils/globals';
import { useLocation } from 'react-router';
import NavItem from './NavItem';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const { pathname } = useLocation();

  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { menuOrientation } = useConfig();
  const { drawerOpen } = useSelector((state) => state.menu);
  const [selectedItems, setSelectedItems] = useState<string | undefined>('');
  const [selectedLevel, setSelectedLevel] = useState<number>(0);
  const [menuItems, setMenuItems] = useState<{ items: NavItemType[] }>({ items: [] });

  useEffect(() => {
    handlerMenuItem();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pathname == '/apps/profile/update') {
      dispatch(activeItem({ openItem: [] }));
    }
  }, [pathname]);

  let getMenu = Menu();
  const handlerMenuItem = () => {
    const isFound = menuItem.items.some((element) => {
      if (element.id === 'group-dashboard') {
        return true;
      }
      return false;
    });

    if (getMenu?.id !== undefined && !isFound) {
      menuItem.items.splice(0, 0, getMenu);
      setMenuItems(menuItem);
    }
  };

  useLayoutEffect(() => {
    setMenuItems(menuItem);
    // eslint-disable-next-line
  }, [menuItem]);

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
  let lastItemIndex = menuItems.items.length - 1;
  let remItems: NavItemType[] = [];
  let lastItemId: string;

  //  first it checks menu item is more than giving HORIZONTAL_MAX_ITEM after that get lastItemid by giving horizontal max
  // item and it sets horizontal menu by giving horizontal max item lastly slice menuItem from array and set into remItems

  if (lastItem && lastItem < menuItems.items.length) {
    lastItemId = menuItems.items[lastItem - 1].id!;
    lastItemIndex = lastItem - 1;
    remItems = menuItems.items.slice(lastItem - 1, menuItems.items.length).map((item) => ({
      title: item.title,
      elements: item.children,
      icon: item.icon
    }));
  }

  const navGroups = menuItems.items.slice(0, lastItemIndex + 1).map((item) => {
    switch (item.type) {
      case 'group':
        return (
          checkSellerSideMenu(user?.type == 'SELLER', item.sellerDisable ?? false, item.onlySeller ?? false) &&
          checkPermissionMatrix(user?.role?.code ?? '', item?.permissionKey ?? [], user?.permissions ?? []) && (
            <NavGroup
              key={item.id}
              setSelectedItems={setSelectedItems}
              setSelectedLevel={setSelectedLevel}
              selectedLevel={selectedLevel}
              selectedItems={selectedItems}
              lastItem={lastItem!}
              remItems={remItems}
              lastItemId={lastItemId}
              item={item}
            />
          )
        );
      case 'item':
        return (
          checkSellerSideMenu(user?.type == 'SELLER', item.sellerDisable ?? false, item.onlySeller ?? false) &&
          checkPermissionMatrix(user?.role?.code ?? '', item?.permissionKey ?? [], user?.permissions ?? []) && (
            <NavItem key={item.id} item={item} level={0} />
          )
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });
  return (
    <Box
      sx={{
        pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
        '& > ul:first-of-type': { mt: 0 },
        display: isHorizontal ? { xs: 'block', lg: 'flex' } : 'block'
      }}
    >
      {navGroups}
    </Box>
  );
};

export default Navigation;

// assets
import { DollarCircleOutlined, SettingOutlined, ClearOutlined, ClockCircleOutlined } from '@ant-design/icons';
import VanIcon from 'assets/images/side-menu/VanIcon';
import SystemNotificationIcon from 'assets/images/side-menu/SystemNotificationIcon';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  DollarCircleOutlined,
  ClearOutlined,
  ClockCircleOutlined,
  SettingOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const settings: NavItemType = {
  id: 'settings',
  title: 'settings',
  type: 'group',
  icon: icons.SettingOutlined,
  permissionKey: [
    'VIEW_CONFIG',
    'VIEW_CURRENCIES',
    'VIEW_SHIPPING_COMPANIES_LIST',
    'VIEW_SHIPPING_COMPANIES_ARCHIVE',
    'VIEW_ACTIVITY_LOG',
    'VIEW_CLEAR_CACHED_DATA',
    'VIEW_SYSTEM_NOTIFICATIONS'
  ],
  children: [
    {
      id: 'config',
      title: 'config',
      type: 'item',
      permissionKey: ['VIEW_CONFIG'],
      url: '/apps/settings/config/list',
      icon: icons.SettingOutlined
    },
    {
      id: 'system-notifications',
      title: 'system-notifications',
      hideTitle: true,
      type: 'item',
      permissionKey: ['VIEW_SYSTEM_NOTIFICATIONS'],
      url: '/apps/settings/system-notifications/list',
      icon: (props) => {
        return <SystemNotificationIcon fillColor={props?.style?.color === 'selected' ? '#222A95' : '#323232'} />;
      }
    },
    {
      id: 'currencies',
      title: 'currencies',
      type: 'item',
      permissionKey: ['VIEW_CURRENCIES'],
      url: '/apps/settings/currencies/list',
      icon: icons.DollarCircleOutlined
    },
    {
      id: 'shipping-companies',
      title: 'shipping-companies',
      type: 'item',
      permissionKey: ['VIEW_SHIPPING_COMPANIES_LIST', 'VIEW_SHIPPING_COMPANIES_ARCHIVE'],
      url: '/apps/settings/shipping-companies/list',
      icon: (props) => {
        return <VanIcon fillColor={props?.style?.color === 'selected' ? '#222A95' : '#323232'} />;
      }
    },
    {
      id: 'activity-log',
      title: 'activity-log',
      type: 'item',
      permissionKey: ['VIEW_ACTIVITY_LOG'],
      url: '/apps/settings/activity-log/list',
      icon: icons.ClockCircleOutlined
    },
    {
      id: 'clear-cached-data',
      title: 'clear-cached-data',
      type: 'item',
      permissionKey: ['VIEW_CLEAR_CACHED_DATA'],
      url: '#',
      icon: icons.ClearOutlined
    }
  ]
};

export default settings;

// third-party
import { combineReducers } from 'redux';

// project import
import profile from './profile';
import menu from './menu';
import snackbar from './snackbar';
import customer from './customer';
import order from './order';
import product from './product';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  profile,
  menu,
  snackbar,
  customer,
  order,
  product
});

export default reducers;

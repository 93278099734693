import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const PaymetnsIcons: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 14 15" {...props} style={{ fill: 'black', ...props.style }}>
      <svg width="14" height="15" viewBox="0 0 14 15" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1709_30980)">
          <path d="M13.3634 7.38001V5.41233C13.3634 4.74512 12.8459 4.20249 12.192 4.14988L10.3647 0.958167C10.1954 0.662979 9.92192 0.451998 9.59473 0.36439C9.26908 0.277379 8.92855 0.323077 8.63709 0.492715L2.3729 4.13962H1.27272C0.570809 4.13962 0 4.71039 0 5.41233V13.0486C0 13.7505 0.570779 14.3213 1.27272 14.3213H12.0907C12.7926 14.3213 13.3634 13.7505 13.3634 13.0486V11.0809C13.733 10.9491 13.9998 10.5992 13.9998 10.185V8.27592C13.9998 7.86166 13.733 7.51179 13.3634 7.38001ZM11.4526 4.13962H8.67936L10.7593 2.92867L11.4526 4.13962ZM10.4432 2.37645L7.41464 4.13962H6.15845L10.1291 1.82786L10.4432 2.37645ZM8.95743 1.04267C9.10129 0.958466 9.26938 0.936094 9.43004 0.978988C9.59255 1.02248 9.72803 1.12751 9.81224 1.27447L9.8129 1.27564L4.89378 4.13962H3.63765L8.95743 1.04267ZM12.7271 13.0486C12.7271 13.3994 12.4415 13.6849 12.0907 13.6849H1.27272C0.921927 13.6849 0.636373 13.3994 0.636373 13.0486V5.41233C0.636373 5.06154 0.921927 4.77599 1.27272 4.77599H12.0907C12.4415 4.77599 12.7271 5.06154 12.7271 5.41233V7.32139H10.818C9.76529 7.32139 8.90896 8.17772 8.90896 9.23045C8.90896 10.2832 9.76529 11.1395 10.818 11.1395H12.7271V13.0486ZM13.3634 10.185C13.3634 10.3605 13.2208 10.5032 13.0453 10.5032H10.818C10.1161 10.5032 9.5453 9.93239 9.5453 9.23045C9.5453 8.52854 10.1161 7.95773 10.818 7.95773H13.0453C13.2208 7.95773 13.3634 8.10035 13.3634 8.27592V10.185Z" />
          <path d="M10.818 8.59424C10.4672 8.59424 10.1816 8.87979 10.1816 9.23058C10.1816 9.58137 10.4672 9.86693 10.818 9.86693C11.1688 9.86693 11.4543 9.58137 11.4543 9.23058C11.4544 8.87979 11.1688 8.59424 10.818 8.59424Z" />
        </g>
        <defs>
          <clipPath id="clip0_1709_30980">
            <rect width="14" height="14" fill="white" transform="translate(0 0.321289)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

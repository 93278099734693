// assets
import { DashboardOutlined } from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  DashboardOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const widget: NavItemType = {
  id: 'group-widget',
  title: 'dashboard',
  type: 'group',
  icon: icons.DashboardOutlined,
  noDropdown: true,
  url: '/apps/dashboard',
  permissionKey: [
    'LIST_DASHBOARD_OVERVIEW',
    'LIST_DASHBOARD_FINANCE',
    'LIST_DASHBOARD_GROSS_MERCHANDISES_VAlUE',
    'LIST_DASHBOARD_AVERAGE_ORDER_VALUE',
    'LIST_DASHBOARD_ORDERS_STATUS',
    'LIST_DASHBOARD_PRODUCT_LIST',
    'LIST_DASHBOARD_PRODUCT_LIBRARY',
    'LIST_DASHBOARD_ACTIVE_PRODUCTS',
    'LIST_DASHBOARD_MOST_ADDED_PRODUCTS_TO_CART',
    'LIST_DASHBOARD_MOST_SOLD_PRODUCTS',
    'LIST_DASHBOARD_WAITING_CARTS',
    'LIST_DASHBOARD_CUSTOMERS',
    'LIST_DASHBOARD_NEW_CUSTOMERS',
    'LIST_DASHBOARD_FIRST_TIME_BUYERS'
  ],
  children: [
    {
      id: 'dashboard',
      title: 'dashboard',
      type: 'item',
      permissionKey: [
        'LIST_DASHBOARD_OVERVIEW',
        'LIST_DASHBOARD_FINANCE',
        'LIST_DASHBOARD_GROSS_MERCHANDISES_VAlUE',
        'LIST_DASHBOARD_AVERAGE_ORDER_VALUE',
        'LIST_DASHBOARD_ORDERS_STATUS',
        'LIST_DASHBOARD_PRODUCT_LIST',
        'LIST_DASHBOARD_PRODUCT_LIBRARY',
        'LIST_DASHBOARD_ACTIVE_PRODUCTS',
        'LIST_DASHBOARD_MOST_ADDED_PRODUCTS_TO_CART',
        'LIST_DASHBOARD_MOST_SOLD_PRODUCTS',
        'LIST_DASHBOARD_WAITING_CARTS',
        'LIST_DASHBOARD_CUSTOMERS',
        'LIST_DASHBOARD_NEW_CUSTOMERS',
        'LIST_DASHBOARD_FIRST_TIME_BUYERS'
      ],
      url: '/apps/dashboard',
      icon: icons.DashboardOutlined
    }
  ]
};

export default widget;

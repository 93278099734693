// project import
import dashboard from './dashboard';
import user from './user';
import catalogue from './catalogue';
import order from './order';
import finance from './finance';
import marketing from './marketing';
import content from './content';
import settings from './settings';

// types
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [dashboard, user, order, catalogue, finance, marketing, content, settings]
};

export default menuItems;

// assets
import { TeamOutlined } from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  TeamOutlined
};

// ==============================|| MENU ITEMS - USER ||============================== //

const user: NavItemType = {
  id: 'user-managment',
  title: 'user-managment',
  type: 'group',
  icon: icons.TeamOutlined,
  permissionKey: ['SUPER', 'LIST_CUSTOMERS', 'ARCHIVE_CUSTOMERS', 'LIST_SELLERS'],
  children: [
    {
      id: 'super-admins',
      title: 'super-admins',
      type: 'item',
      permissionKey: ['SUPER'],
      icon: icons.TeamOutlined,
      url: '/apps/user-managment/super-admins/list'
    },
    {
      id: 'admins',
      title: 'admins',
      type: 'item',
      permissionKey: ['SUPER'],
      icon: icons.TeamOutlined,
      url: '/apps/user-managment/admins/list'
    },
    {
      id: 'sellers',
      title: 'sellers',
      type: 'item',
      permissionKey: ['LIST_SELLERS'],
      icon: icons.TeamOutlined,
      url: '/apps/user-managment/seller/list'
    },
    {
      id: 'customers',
      title: 'customers',
      type: 'item',
      permissionKey: ['LIST_CUSTOMERS', 'ARCHIVE_CUSTOMERS'],
      icon: icons.TeamOutlined,
      url: '/apps/user-managment/customers/list'
    },
    {
      id: 'role',
      title: 'role',
      type: 'item',
      permissionKey: ['SUPER'],
      icon: icons.TeamOutlined,
      url: '/apps/user-managment/role/list'
    }
  ]
};

export default user;

// assets
import { FormOutlined, ContainerOutlined, PieChartOutlined } from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  FormOutlined,
  ContainerOutlined,
  PieChartOutlined
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const content: NavItemType = {
  id: 'content',
  title: 'content',
  type: 'group',
  icon: icons.PieChartOutlined,
  permissionKey: [
    'VIEW_MEDEX_SLIDERS_LIST',
    'VIEW_MEDEX_SLIDER_ARCHIVE',
    'VIEW_BRANDS_SLIDERS_LIST',
    'VIEW_BRANDS_SLIDER_ARCHIVE',
    'VIEW_PRODUCT_CONTENT_LIST',
    'VIEW_FOOTER_LIST',
    'VIEW_PRIVILEGES_LIST',
    'VIEW_PRIVILEGE_ARCHIVE',
    'VIEW_BLOG_LIST',
    'VIEW_BLOG_ARCHIVE',
    'VIEW_SELLER_FAQ_LIST',
    'VIEW_SELLER_FAQ_ARCHIVE',
    'VIEW_CUSTOMER_FAQ_LIST',
    'VIEW_CUSTOMER_FAQ_ARCHIVE',
    'VIEW_SELLER_EDUCATION_CENTER_LIST',
    'VIEW_SELLER_EDUCATION_CENTER_ARCHIVE',
    'VIEW_POLICIES_LIST'
  ],
  children: [
    {
      id: 'pages',
      title: 'home-page-content',
      type: 'collapse',
      permissionKey: [
        'VIEW_MEDEX_SLIDERS_LIST',
        'VIEW_MEDEX_SLIDER_ARCHIVE',
        'VIEW_BRANDS_SLIDERS_LIST',
        'VIEW_BRANDS_SLIDER_ARCHIVE',
        'VIEW_PRODUCT_CONTENT_LIST',
        'VIEW_FOOTER_LIST',
        'VIEW_PRIVILEGES_LIST',
        'VIEW_PRIVILEGE_ARCHIVE',
        'ADMINS'
      ],
      icon: icons.FormOutlined,
      children: [
        {
          id: 'mega-menu',
          title: 'mega-menu',
          type: 'item',
          permissionKey: ['ADMINS'],
          url: '/apps/marketing/mega-menu'
        },
        {
          id: 'medex-slider',
          title: 'medex-slider',
          type: 'item',
          permissionKey: ['VIEW_MEDEX_SLIDERS_LIST', 'VIEW_MEDEX_SLIDER_ARCHIVE'],
          url: '/apps/content/medex-slider/list'
        },
        {
          id: 'brands-slider',
          title: 'brands-slider',
          type: 'item',
          permissionKey: ['VIEW_BRANDS_SLIDERS_LIST', 'VIEW_BRANDS_SLIDER_ARCHIVE'],
          url: '#'
        },
        {
          id: 'product-content',
          title: 'product-content',
          type: 'item',
          permissionKey: ['VIEW_PRODUCT_CONTENT_LIST'],
          url: '/apps/content/product-content/list'
        },
        {
          id: 'footer',
          title: 'footer',
          type: 'item',
          permissionKey: ['VIEW_FOOTER_LIST'],
          url: '/apps/content/footer/list'
        }
      ]
    },
    {
      id: 'other-content',
      title: 'other-content',
      type: 'collapse',
      permissionKey: [
        'VIEW_COMPANY_PROFILE',
        'VIEW_PRIVILEGES_LIST',
        'VIEW_PRIVILEGE_ARCHIVE',
        'VIEW_CUSTOMER_FAQ_LIST',
        'VIEW_CUSTOMER_FAQ_ARCHIVE',
        'VIEW_SELLER_FAQ_LIST',
        'VIEW_SELLER_FAQ_ARCHIVE',
        'VIEW_SELLER_EDUCATION_CENTER_LIST',
        'VIEW_SELLER_EDUCATION_CENTER_ARCHIVE',
        'VIEW_BLOG_LIST',
        'VIEW_BLOG_ARCHIVE',
        'VIEW_POLICIES_LIST'
      ],
      icon: icons.ContainerOutlined,
      children: [
        {
          id: 'company-profile',
          title: 'company-profile',
          type: 'item',
          permissionKey: ['VIEW_COMPANY_PROFILE'],
          url: '/apps/content/company-profile/list'
        },
        {
          id: 'privileges',
          title: 'privileges',
          type: 'item',
          permissionKey: ['VIEW_PRIVILEGES_LIST', 'VIEW_PRIVILEGE_ARCHIVE'],
          url: '/apps/content/priviliges/list'
        },

        {
          id: 'customer-faq',
          title: 'customer-faq',
          sellerDisable: true,
          type: 'item',
          permissionKey: ['VIEW_CUSTOMER_FAQ_LIST', 'VIEW_CUSTOMER_FAQ_ARCHIVE'],
          url: '/apps/content/customer-faq/list'
        },
        {
          id: 'seller-faq',
          title: 'seller-faq',
          type: 'item',
          permissionKey: ['VIEW_SELLER_FAQ_LIST', 'VIEW_SELLER_FAQ_ARCHIVE'],
          url: '/apps/content/seller-faq/list'
        },
        {
          id: 'seller-education-center',
          title: 'seller-education-center',
          type: 'item',
          permissionKey: ['VIEW_SELLER_EDUCATION_CENTER_LIST', 'VIEW_SELLER_EDUCATION_CENTER_ARCHIVE'],
          url: '/apps/content/seller-education-center/list'
        },
        {
          id: 'blog',
          title: 'blog',
          type: 'item',
          permissionKey: ['VIEW_BLOG_LIST', 'VIEW_BLOG_ARCHIVE'],
          url: '/apps/content/blog/list'
        },

        {
          id: 'policies',
          title: 'policies',
          type: 'item',
          permissionKey: ['VIEW_POLICIES_LIST'],
          url: '/apps/content/policies/list'
        }
      ]
    }
  ]
};

export default content;

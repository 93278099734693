import { SuccessResponseEntity } from 'types/app';
import { ExportItemProps } from 'types/export';
import axiosServices, { responseError, responseSuccess } from 'utils/axios';
import { isErrorResponse } from 'utils/globals';

export function listFinance(
  page: number,
  params: any,
  i18n: string,
  search: string,
  sort: { sort_by: string | null; sort_type: string | null },
  setList: (data: any[]) => void,
  setPagination: (data: any) => void,
  offset: number,
  isExporting?: boolean,
  exportIds?: string[],
  updateExportedList?: (item: ExportItemProps) => void
) {
  let endpoint = 'finances/transactions';
  if (isExporting) endpoint += `/data/export`;

  const searchParams = new URLSearchParams();

  searchParams.append('page', page.toString());
  searchParams.append('offset', offset.toString());
  searchParams.append('search[value]', search);
  if (sort.sort_by) searchParams.append('sort_by', sort.sort_by);
  if (sort.sort_type) searchParams.append('sort_type', sort.sort_type);
  if (params.seller) searchParams.append('custom_search[seller_id]', params.seller);
  if (params.customer) searchParams.append('custom_search[customer_id]', params.customer);
  if (params.date_from) searchParams.append('custom_search[date_from]', params.date_from);
  if (params.date_to) searchParams.append('custom_search[date_to]', params.date_to);
  if (params.status) searchParams.append('custom_search[status]', params.status);
  if (params.shipping_company) searchParams.append('custom_search[shipping_company]', params.shipping_company);
  if (params.payment_method) searchParams.append('custom_search[payment_method]', params.payment_method);
  if (isExporting && exportIds?.length) searchParams.append('ids', exportIds.join(','));

  endpoint += `?${searchParams.toString()}`;
  return axiosServices.get(endpoint, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      if (!isExporting) {
        setPagination(res.data.items.meta.pagination);
        setList(res.data.items.data);
        if (!res.data.success) responseError();
        return res.data;
      } else {
        // const item = {
        //   file: res.data.items?.name,
        //   uuid: res.data.items?.uuid,
        //   endpoint: endpoint
        // };
        // updateExportedList?.(item);
        responseSuccess('export.success', 'common');
      }
    },
    (err: any) => {
      responseError();
      return err;
    }
  );
}

export function listEarnings(
  page: number,
  params: any,
  i18n: string,
  type: string,
  search: string,
  sort: { sort_by: string | null; sort_type: string | null },
  setList: (data: any[]) => void,
  setPagination: (data: any) => void,
  offset: number,
  isExporting?: boolean,
  exportIds?: string[],
  updateExportedList?: (item: ExportItemProps) => void
) {
  const searchParams = new URLSearchParams();
  searchParams.append('type', type);
  searchParams.append('page', page.toString());
  searchParams.append('offset', offset.toString());
  if (search) searchParams.append('search[value]', search);
  for (let param in params) {
    if (params[param]) searchParams.append(param, params[param]);
  }
  if (sort.sort_by) searchParams.append('sort_by', sort.sort_by);
  if (sort.sort_type) searchParams.append('sort_type', sort.sort_type);
  if (isExporting && exportIds?.length) searchParams.append('ids', exportIds.join(','));

  const endpoint = `/finances/orders/earnings-list${isExporting ? '/data/export' : ''}?${searchParams.toString()}`;
  return axiosServices.get(endpoint, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      if (!isExporting) {
        setPagination(res.data.items.meta.pagination);
        setList(res.data.items.data);
        if (!res.data.success) responseError();
        return res.data;
      } else {
        // const item = {
        //   file: res.data.items?.name,
        //   uuid: res.data.items?.uuid,
        //   endpoint: endpoint
        // };
        // updateExportedList?.(item);
        responseSuccess('export.success', 'common');
      }
    },
    (err: any) => {
      responseError();
      return err;
    }
  );
}

export function getEarningsUserDetials(i18n: string, params: any, type: string, setItem: (data: any) => void) {
  return axiosServices
    .get(
      `/finances/orders/earnings-block?type=${type}
      &status=${params.status ?? ''}
      &date_from=${params.date_from ?? ''}
      &date_to=${params.date_to ?? ''}
      &seller_id=${params.seller ?? ''}
      &payment_method=${params.payment_method ?? ''}`,
      { headers: { 'Accept-Language': i18n } }
    )
    .then((res) => {
      setItem(res.data.items);
    });
}

export function getPastEarningDetials(id: string, type: string, params: any, i18n: string, setItem: (data: any) => void) {
  return axiosServices
    .get(`/finances/orders/${id}/earnings-details?type=${type}&seller_id=${params.seller ?? ''}`, { headers: { 'Accept-Language': i18n } })
    .then((res) => {
      setItem(res.data.item.products);
    });
}

export function getShippingCompanies(i18n: string, setList: (data: any[]) => void, product_ids: string = '', checkOTO: boolean = false) {
  return axiosServices
    .get(`/finances/get-shipping-companies?checkOTO=${checkOTO}&orderProductIds=${product_ids}`, {
      headers: { 'Accept-Language': i18n }
    })
    .then((res) => {
      setList(res.data.item.ShippingCompany);
    });
}

export function getReleasePayment(id: string, i18n: string, setReleasePayment: (value: any) => void) {
  return axiosServices.get(`/finances/transactions/${id}/release-payment`, { headers: { 'Accept-Language': i18n } }).then((res) => {
    setReleasePayment(res.data.items);
  });
}

export function saveReleasePayment(id: string, i18n: string, form: FormData) {
  return axiosServices
    .post(`/finances/transactions/${id}/release-payment`, form, { headers: { 'Accept-Language': i18n } })
    .then((res) => {
      if (res.data?.code === 200) responseSuccess('accounting.success.release_payment', 'finance');
      return res.data;
    })
    .catch((err) => {
      if (isErrorResponse(err)) responseError(err.message);
      else responseError();
      return err;
    });
}

export function bulkSaveReleasePayment(i18n: string, payload: any) {
  return axiosServices
    .post(`/finances/transactions/bulk-release-payment`, payload, { headers: { 'Accept-Language': i18n, Media: '1' } })
    .then((res) => {
      if (res.data?.code === 200) responseSuccess('accounting.success.release_payment', 'finance');
      return res.data;
    })
    .catch((err) => {
      if (isErrorResponse(err)) responseError(err.message);
      else responseError();
      return err;
    });
}

export async function getInvoicesCount({ i18n }: { i18n: string }) {
  const res = await axiosServices.get<SuccessResponseEntity<{ count: number }>>('/invoices/list/count', {
    headers: { 'Accept-Language': i18n }
  });
  if (!res.data.success) throw new Error(res.data.message);
  return res.data.item;
}
